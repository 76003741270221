import React from "react";
import { Helmet } from "react-helmet";
import {LinkWithUTM as Link} from '../../components/LinkWithUTM'
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "../../../scss/main.scss";
import Trustpilot from "../../components/Trustpilot/Trustpilot";
import CqcApproved from "../../components/CqcApproved";

import {
	ConditionSplash,
	ConditionDescription,
	ConditionDescriptionRightPanel,
	ConditionDescriptionRightPanelParagraph,
	ConditionInfographicSection,
	ConditionInfographicSectionIntro,
	ConditionInfographicMainContent,
	ConditionInfographicDescription,
	ConditionInfographicDescriptionParagraph,
	ConditionsInforgraphicTypes,
	ConditionsInfographicTypesHeading,
	ConditionsInfographicTypesDetail,
	ConditionsInfographicTypesDetailSection,
	ConditionsInfographicSymptons,
	ConditionsInfographicSymptonsHeader,
	ConditionsInfographicSymptonDiagram,
	ConditionsInfographicSymptonDiagramMainCopy,
	ConditionsInfographicSymptonDiagramTopics,
	ConditionsInfographicSymptonDiagramTopic,
	ConditionsInfographicSymptonDiagramTopicHeading,
	ConditionsInfographicSymptonDiagramTopicCopy,
	ConditionStats,
	ConditionStatsMainContent,
	ConditionStat,
	ConditionStatCopy,
	ConditionStatCopyInline,
	ConditionStatCopyEmph,
	ConditionStatCopyEmphInline,
	ConditionLivein,
	ConditionLiveinHeader,
	ConditionLiveinHeaderCopy,
	ConditionLiveinHomeCard,
	ConditionLiveinHomeCardMainCopy,
	ConditionLiveinHomeCardMainTip,
	ConditionLiveinHomeCardMainTipTitle,
	ConditionLiveinHomeCardMainTipCopy,
	ConditionTrainingCard,
	ConditionTrainingCardMainCopy,
	ConditionTrainingCardTip,
	ConditionTrainingCardTipItem,
} from '../../components/Conditions'

export default function Dementia() {
	const dementiaBackgroundFluid = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "dementia-image-02.jpg"}){
				childImageSharp {
					fluid(quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`).background.childImageSharp.fluid
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Stroke care at home</title>
				<meta property="og:title" content="Edyn Care | Stroke care at home"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/condition_stroke_splash.jpeg"/>
				<meta property="og:url" content="https://www.edyn.care/condition/stroke" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/condition/stroke" />
				<meta name="twitter:title" content="Edyn Care | Stoke" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<ConditionSplash>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/circle-dementia.svg" 
							placeholder="blurred"
						/>
						<h3>Stroke care at home</h3>
					</ConditionSplash>
					<ConditionDescription>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/condition_stroke_splash.jpeg"
							placeholder="blurred"
						/>
						<ConditionDescriptionRightPanel>
							<h3>
								Recovering from a stroke is different for everyone.
							</h3>
							<ConditionDescriptionRightPanelParagraph>
								It can be hard to watch a loved one experience the effects of a stroke and rehabilitation can be both lengthy and unpredictable. However, effective rehabilitation and care is key to ensuring the best possible quality of life.
							</ConditionDescriptionRightPanelParagraph>
							<ConditionDescriptionRightPanelParagraph>
								At Edyn we know first hand the power of receiving the right care at home can have a positive impact on a person’s rehabilitation and quality of life. Thanks to ongoing research, live-in care techniques and assistive technology, it is possible to live well with stroke, and remain at home. We understand that everyone’s journey is different and that some people will need a long period of rehabilitation before they can recover their independence, while many never fully recover and need ongoing support after their stroke. 							</ConditionDescriptionRightPanelParagraph>
							</ConditionDescriptionRightPanel>
					</ConditionDescription>
					<ConditionInfographicSection>
						<ConditionInfographicSectionIntro>
							<h1>What is a Stroke?</h1>
							<p className="secondary pro">
								A stroke is when the blood supply to part of the brain is cut off. 
							</p>
						</ConditionInfographicSectionIntro>
						<ConditionInfographicMainContent>
							<ConditionInfographicDescription>
								<h3>
									There are an estimated 100,000 strokes per year in the UK. All strokes are different.  
								</h3>
								<ConditionInfographicDescriptionParagraph>
									As we age, our arteries become harder and narrower and more likely to become blocked. However, certain medical conditions and lifestyle factors can speed up this process and increase your risk of having a stroke. The sooner a person receives treatment for a stroke, the less damage is likely to happen. For some people the effects may be relatively minor and may not last long. Others may be left with serious problems that make them require long-term support.
								</ConditionInfographicDescriptionParagraph>
								<ConditionInfographicDescriptionParagraph>
								</ConditionInfographicDescriptionParagraph>
							</ConditionInfographicDescription>
							<ConditionsInforgraphicTypes>
								<ConditionsInfographicTypesHeading>
									There are three different types of stroke:
								</ConditionsInfographicTypesHeading>
								<ConditionsInfographicTypesDetail>
									<ConditionsInfographicTypesDetailSection title={`Ischaemic stroke`}>
										<ul>
											<li>
												This is caused by a blockage cutting off the blood supply to the brain and is the most common type of stroke
											</li>
											<li>
												This type of stroke accounts for around 85% of all strokes in the UK
											</li>
											<li>
												The risk of ischaemic stroke increases as your loved on ages because arteries often become narrower over time
											</li>
											<li>
												High blood pressure, high cholesterol, obesity and diabetes can all increase the risk of an ischaemic stroke
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Hemorrhagic stroke`}>
										<ul>
											<li>
												This is caused by bleeding in or around the brain. This type of stroke occurs when a blood vessel bursts inside the brain. These burst blood vessels are usually caused by high blood pressure. 
											</li>
											<li>
												They make up about 15% of stroke cases.

											</li>
											<li>
												Tends to affect younger people is most common in period ages between 45 and 70.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Transient ischaemic attack or TIA`}>
										<ul>
											<li>
												A TIA is also referred to as a mini stroke. It results from a temporary disruption in the blood flow to the brain. 
											</li>
											<li>
												The symptoms only last for a short amount of time. This is because it is only a temporary blockage to the blood getting to the brain. 
											</li>
											<li>
												It is often a precursor to a full-blown stroke and should never be ignored.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
								</ConditionsInfographicTypesDetail>
							</ConditionsInforgraphicTypes>
							<ConditionsInfographicSymptons>
								<ConditionsInfographicSymptonsHeader>
								During a stroke, the lack of blood flow starves the brain of oxygen. The lack of oxygen and nutrients kills brain cells and can have a range of serious implications.
								</ConditionsInfographicSymptonsHeader>
								<ConditionsInfographicSymptonDiagram>
									<ConditionsInfographicSymptonDiagramMainCopy>
										The main symptoms of stroke can be remembered with the word <em>FAST</em>
									</ConditionsInfographicSymptonDiagramMainCopy>
									<ConditionsInfographicSymptonDiagramTopics>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Face:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												the face may have dropped on 1 side, the person may not be able to smile, or their mouth or eye may have dropped
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Arms:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												the person with suspected stroke may not be able to lift both arms and keep them there because of weakness or numbness in 1 arm.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Speech:
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												their speech may be slurred or garbled, or the person may not be able to talk at all despite appearing to be awake; they may also have problems understanding what you’re saying to them.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Time:
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												it’s time to dial 999 immediately if you see any of these signs or symptoms.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
									</ConditionsInfographicSymptonDiagramTopics>
								</ConditionsInfographicSymptonDiagram>
							</ConditionsInfographicSymptons>
						</ConditionInfographicMainContent>
					</ConditionInfographicSection>
					<ConditionStats>
						<BackgroundImage className="f-c-image" fluid={dementiaBackgroundFluid}>
							<h1>Stroke facts and stats</h1>
						</BackgroundImage>
						<ConditionStatsMainContent>
							<ConditionStat>
								<ConditionStatCopyEmph>almost two thirds</ConditionStatCopyEmph>
								<ConditionStatCopy>
									of stroke survivors leave hospital with a disability
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>there are</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>1.2 million</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									stroke survivors in the UK
								</ConditionStatCopy>
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopy>
									someone has a stroke every
								</ConditionStatCopy>
								<ConditionStatCopyEmph>five minutes</ConditionStatCopyEmph>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>Black people are</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>twice as likely</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									to have a stroke compared to white people
								</ConditionStatCopy>
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>There are more than</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>100,000</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									strokes in the UK each year
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>Each year, in England the NHS and social care costs of stroke are around</ConditionStatCopyInline>
								<ConditionStatCopyEmph>£1.7 billion</ConditionStatCopyEmph>
								{/*   <hr>  */}
							</ConditionStat>
							{/* <div className="stats">
								<p className="primary">A person is diagnosed with dementia every</p>
								<h3 >three minutes</h3>
							</div> */}
						</ConditionStatsMainContent>
					</ConditionStats>
					<ConditionLivein>
						<ConditionLiveinHeader>
							<h1>Stroke live-in care </h1>
							<ConditionLiveinHeaderCopy>
								We understand that with patience, trust and genuine care, we
								can help those have experienced a stroke maintain a high quality
								of life and live comfortably in their own home.
							</ConditionLiveinHeaderCopy>
						</ConditionLiveinHeader>
						<ConditionLiveinHomeCard title={`Ensuring your home is safe for stroke rehabilitation `}>
							<ConditionLiveinHomeCardMainCopy>
									When considering live-in care it’s important to ensure that the home of the care recipient is safe and secure. Does it compensate for any difficulties they are experiencing? Does it keep them safe? Does it support their mobility? <br />
								<br />
									At Edyn as part of our approach we complete a home risk assessment for all of our clients. Our care managers ensure that your home is the best possible setting for care. Small changes can make big differences. 
							</ConditionLiveinHomeCardMainCopy>
							<ConditionLiveinHomeCardMainTip>
								<ConditionLiveinHomeCardMainTipTitle>Top stroke care tip:</ConditionLiveinHomeCardMainTipTitle>
								<br />
								<ConditionLiveinHomeCardMainTipCopy>
									Ensuring mobility areas are safe with adequate space and having a dedicated area for medication with a well defined medication schedule are both crucial.								</ConditionLiveinHomeCardMainTipCopy>
							</ConditionLiveinHomeCardMainTip>
						</ConditionLiveinHomeCard>
						<ConditionTrainingCard title={`Professional carers trained specifically for stroke recovery:`}>
							<ConditionTrainingCardMainCopy>
								Our professional care teams receive on-going training,
								mentoring and support based on the latest thinking and
								research that ensure they have the skills to deliver
								positive stroke rehabilitation. This includes:
							</ConditionTrainingCardMainCopy>
							<ConditionTrainingCardTip>
								<ConditionTrainingCardTipItem>Creative activities</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Monitoring behaviour and cognitive symptoms</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Active recall of preserving memories</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Sensory and dental care</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Maintaining exercise</ConditionTrainingCardTipItem>
							</ConditionTrainingCardTip>
						</ConditionTrainingCard>
					</ConditionLivein>
					{/* <section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<h3 className="text-with-quotes">
										<span className="customer-words-group__quotes">“</span>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.
										<span className="customer-words-group__quotes">”</span>
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<div className="customer-images-bubbles">
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers1.png" />
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers2.png" />
								</div>
								<div className="customer-names">
									<p className="secondary">
										Sarah M.
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie M.
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section> */}

					<section className="live-in dementia">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care.</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Stay informed and up to date with our smart online portal.
								</p>
							</div>
						</div>
						<Link to="/approach/" className="button-link">
							<button className="btn-primary">
								Learn more about our process
							</button>
						</Link>
					</section>
					{/* <Trustpilot /> */}
					<CqcApproved />
				</main>
				<Footer />
			</div>
		</>
	);
}
